import React from 'react';
import UserFullName from '../Auth/UserFullName';
import UserId from '../Auth/UserId'

function Nav() {
  const userId = UserId();
  return (
    <nav>
      <div className="leftIntend1 ">
       ADECT BLR Certification Pace Dashboard
        </div>    
        <div className="leftIntend">
          <UserFullName />
          </div> 
          &nbsp; <img className="card-img-top" src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" + userId} alt="Card cap" />     
    </nav>


  );
}

export default Nav;