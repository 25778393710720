import React, { forwardRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import DownloadIcon from '@mui/icons-material/Download';
import XLSX from 'xlsx';
import Typography from "@material-ui/core/Typography";

const tableIcons = {
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ color: "#f0e8ec" }} />) 
};

function Tester() {
  const [data, setData] = useState([]);
  const [refreshTime, setRefreshTime] = useState([]);
  const tableRef = React.createRef();

      const columns = [
      { title: "UserId", field: "testerId", },
      { title: "TeamName", field: "teamName"},
      { title: "Manager", field: "manager"},
      { title: "Skills Assigned", field: "skillsAssigned" },
      { title: "Skills Closed", field: "skillsClosed" },
      { title: "AHTUtilized  (mins)", field: "ahtUtilized" },
      { title: "Skills Queued ", field: "skillsQueued" },
      { title: "AHTRequired  (mins)", field: "ahtRequired" }
  
    ]
  useEffect(() => {
      fetch(process.env.REACT_APP_MAGNUS_BACKEND + "testersSkillsDataGrouped")
      .then(resp => resp.json())
      .then(resp => {
        console.log(resp);
        resp.testersPaceData.forEach(function (paceData) {
          delete paceData['connectTime'];
          delete paceData['onqueueMinutes'];
          delete paceData['offqueueMinutes'];
          delete paceData['noSkillsMinutes'];
          delete paceData['idleMinutes'];
          delete paceData['unavailableMinutes'];
          delete paceData['remainderOfTime'];
          paceData.ahtUtilized = Math.round(paceData.ahtUtilized);
          paceData.ahtRequired = Math.round(paceData.ahtRequired);
      });

         setData(resp.testersPaceData)
         setRefreshTime(resp.lastUpdated);
      })
      .catch(err => {
        setData(null)
        setRefreshTime(null);
      })
  }, [])

  const downloadExcel=()=>{
    const newData=data.map(row=>{
      delete row.tableData
      return row
    })
    const workSheet=XLSX.utils.json_to_sheet(newData)
    const workBook=XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook,workSheet,"data")
    XLSX.write(workBook,{bookType:"xlsx",type:"binary"})
    XLSX.writeFile(workBook,"PaceData.xlsx")
  }

  const MyNewTitle = ({ text, variant }) => (
    <Typography
      variant={variant}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color:"silver",
        fontWeight: 900
      }}  >
      {text}
    </Typography>
  );

  return (
    <div className="App">
       {(() => {
        if (refreshTime!=null) {
          return (
            <p className="refresh" >Last refreshed at {refreshTime.slice(0,6).join(":")} IST</p> 
          )
        } 
        else {
          return (<p className="serverFault" >Skills data from CIC_Database is NOT ACCESSIBLE... Please reach out to Blr-QA-Tools(@alexa-tools-titans) </p>)
        }
        })()}

      <div className="card1">
      <MaterialTable 
       title={<MyNewTitle variant="h5" text="Testers Pace records " />}
        tableRef={tableRef}
        icons={tableIcons}
        data={data}
        columns={columns}
        actions={[
          { icon:()=><DownloadIcon />,
          tooltip:"Export to Excel",
           onClick:()=>downloadExcel(),
          isFreeAction:true}
        ]}
        options={{
          maxBodyHeight: 1200,
          maxBodyWidth: 48,
          headerStyle: {
            backgroundColor: 'rgb(15, 32, 78)',
            color: '#FDFEFE',
            fontSize: 15,
            height:4,
            width: 20,
            maxWidth: 150,
            paddingTop:2,
            paddingBottom:2,
            fontWeight: "bold", 
            textAlign:"center",
            position: 'sticky', top: 0
          },
          rowStyle: x => {
            if (x.tableData.id % 2) {
                return {backgroundColor: "white",height:35 }
            }
            else {
              return {backgroundColor: "#f0e8ec",height:35}
          }
        },
          cellStyle: {
            fontWeight: "500",
            fontSize: 13, 
            textAlign:"center",
            paddingTop:3,
            paddingBottom:3,
            width: 2,
           minWidth: 2
          },
          paging: false,
          search: true,
          sorting: true,
          tableLayout:'auto'
        }}
      />
      </div>
      <p></p>
      <a className="bug" href="https://tiny.amazon.com/c0uxzrr3" target='_blank'>    Report an Issue</a>
    </div>
  );
}

export default Tester;