import React, { useEffect, useState } from 'react'
import './App.css'
import Amplify, { Auth, Hub } from 'aws-amplify'
import UserAccess from './components/Auth/UserAccess'
import UserId from './components/Auth/UserId'
import UserNotFound from './components/pages/UserNotFound'
import Nav from './components/layouts/Nav'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Manager from './components/pages/Manager'
import Tester from './components/pages/Tester'
 
Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    // userPoolId: 'us-east-1_Q8d1lhsxp', //NASA
    userPoolId: 'us-east-1_xxWUK1Sor', //CopsHome
    // userPoolId: 'us-east-1_iRXHZW9cK', //Aquiline
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    // userPoolWebClientId: '5bsdlb9qbqcj6ogptv13c11ak5', //NASA-Aq
    // userPoolWebClientId: '7coqm9rnla4adqus6uvqn5e4om', //NASA-Dash
    userPoolWebClientId: '4s8eb0qsnrdolnq6ofsugr05t', //CopsHome_NoSecret
    // userPoolWebClientId: 'rlf3140rlumcmtpvc3nsu1jkc', //Aquiline_AppClient
    // userPoolWebClientId: 'ung6rud95p32m4esjhps1p4bi', //Aquiline_NoSecret
    // OPTIONAL - Hosted UI configuration
    oauth: {
      // domain: 'nasadash.auth.us-east-1.amazoncognito.com', //NASA
      domain: 'copshomefederate.auth.us-east-1.amazoncognito.com', //CopsHome
      // domain: 'aquilinefederate.auth.us-east-1.amazoncognito.com', //Aquiline
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
      // redirectSignIn: 'https://beta.aquiline.cops.alexa.a2z.com',
      // redirectSignIn:  'http://localhost:3000/',
      // redirectSignIn: 'https://aquiline-react-static.s3.amazonaws.com/index.html',
      redirectSignOut:
        'https://aquiline-react-static.s3.amazonaws.com/login/oauth2/code/cognito',
      responseType: 'code', // REFRESH token will only be generated when the responseType is code
    },
  },
})
function App() {
  const [user, setUser] = useState(null)
  const [allTesters, setAllTesters] = useState([]);
  const [allManagers, setAllManagers] = useState([]);
  const currentUser = UserId()
  let [userData, setUserData] = useState(null)
  useEffect(() => {
    getUser().then((userData) => setUserData(userData))
  }, [])
  function getUser() {
    return (
      Auth.currentAuthenticatedUser()
        .then((userData) => console.log('userinfoAppJs', userData))
        .catch(() => Auth.federatedSignIn())
    )
  }
  const accessLevel = UserAccess()
  useEffect(() => {
    fetch(process.env.REACT_APP_MAGNUS_BACKEND + "allTesters")
    .then(resp => resp.json())
    .then(resp => {
    setAllTesters(resp);
    })
    .catch(err => {
      setAllTesters(null);
    })
  }, [])
 
  useEffect(() => {
    fetch(process.env.REACT_APP_MAGNUS_BACKEND + "allManagers")
    .then(resp => resp.json())
    .then(resp => {
      setAllManagers(resp);
    })
    .catch(err => {
      setAllManagers(null)
    })
  }, [])

  allManagers.push("mmadhan","ajgosavi","hvinu","jeelann","daananya");
  
  var designation;
  if( !((allTesters.indexOf(currentUser) > -1) || (allManagers.indexOf(currentUser) > -1))){
    return (
      <div>
        <Router>
             <Switch>
               <Route exact path="/" component={UserNotFound} />
               <Route exact path="/Home" component={UserNotFound} />
               <Route exact path="/ManagerPage" component={UserNotFound} />
             </Switch>
         </Router>
       </div>
     )
  }
 
  if((currentUser=== 'bbindu')|| (currentUser=== 'daananya')) {
     designation = 'Tester';
  }
  else
  {  designation = accessLevel; }
 
  console.log('user name is '+currentUser+' so my access is ' + designation);
  if (designation === 'Tester'  ) {
    return (
      <div >
        <Nav />
        <Router>
          <div className="App">
            <Switch>
              <Route exact path="/" component={Tester} />
              <Route exact path="/TesterView" component={Tester} />
              <Route exact path="/QAadmin" component={Manager} />
            </Switch>
          </div>
        </Router>
      </div>
    )
  } else if (designation === 'Manager' || designation === 'Admin') {
    return (
      <div >
        <Nav />
        <Router>
          <div className="App">
            <Switch>
              <Route exact path="/" component={Manager} />
              <Route exact path="/Manager" component={Manager} />
              <Route exact path="/TesterView" component={Tester} />
            </Switch>
          </div>
        </Router>
      </div>
    )
  } else if (designation === 'unknown') {
    return (
     <div>
       <Router>
            <Switch>
              <Route exact path="/" component={UserNotFound} />
              <Route exact path="/Home" component={UserNotFound} />
              <Route exact path="/ManagerPage" component={UserNotFound} />
            </Switch>
        </Router>
      </div>
    )
  }
}
 
export default App