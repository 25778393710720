import User from './User';

var uID = null;
var userId = "notLoggedInDa";

const UserId = () => {
  if (uID !== "notLoggedInDa" && uID != null) {
    return (uID);
  }

  const user = User();

  if (user !== null) {
    userId = user.username;
    userId = userId.replace("@amazon.com", "");
    userId = userId.replace("copshomeusers_", "");
    uID = userId;
  }
  console.log("userinfoName: ", userId)

  if (userId === "mmadhan") {
    userId = "poushalm";
    uID = "poushalm";
  }

  console.log("userinfoName2: ", userId)

  return (userId);
}

export default UserId;