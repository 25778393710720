import { Auth } from 'aws-amplify';

const User = () => {

  const user = Auth.user;

  if (user != null) {
    console.log("PrintingUser: ", user)
    return (user)
  } else {
    console.log("PrintingUser: null", user)
    return (null)
  }

}

export default User;